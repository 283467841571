import { isBefore, subSeconds } from 'date-fns';
import { siteLaunchStartTime } from '../data/dates';
import { Phases } from '../data/enum/phases';
import { getServerTime } from './getServerTime';

// the first date is before the second date - thresholdInSeconds
const isBeforeWithThreshold = (
  date: Date | number,
  dateToCompare: Date | number,
  thresholdInSeconds: number,
): boolean => {
  return isBefore(date, subSeconds(dateToCompare, thresholdInSeconds));
};

export const getCurrentPhase = (): Promise<Phases> => {
  return getServerTime().then((serverTime) => {
    // server cache is 10 seconds, let's add a 30 second threshold
    const threshold = 30;

    // if serverTime is before day1StartTime - 30s we show countdown
    if (isBeforeWithThreshold(serverTime, siteLaunchStartTime, threshold)) {
      return Phases.COUNTDOWN;
    }

    return Phases.LAUNCH;
  });
};
