export const getAnalyticsScript = () => {
  switch (process.env.NODE_ENV) {
    case 'staging':
      return 'https://assets.adobedtm.com/1063865e0018/16516ca579a4/launch-ee67b8be0b54-development.min.js';
    case 'production':
      return 'https://assets.adobedtm.com/1063865e0018/16516ca579a4/launch-a865e0d50b78.min.js';
    default:
      return 'https://assets.adobedtm.com/1063865e0018/16516ca579a4/launch-ee67b8be0b54-development.min.js';
  }
};

/* eslint-disable no-shadow */
export enum AnalyticsEvents {
  // legacy events from the first HD site
  SectionView = 'hd21-sectionView : ',
  DayChanged = 'hd21-header-day0',
  ContactYourDealerClicked = 'hd21-header-contact-your-dealer',
  ProductViewRiderStories = 'hd21-rider-stories-productView-',
  VideoPlayed = 'hd21-home-video',
  VideoProgress = 'hd21-home-video-',
  RegisterClicked = 'hd21-home-register',
  RiderStoriesItemsCtaClicked = 'hd21-riderstories-itemsCTA',
  DealerLoginClicked = 'hd21-header-dealer-login',
  TurnOnTheRadio = 'hd21-cvo-turn-radio-on',
  CoolingSeatClicked = 'hd21-cvo-cooling-seat-',
  CvoHeatingSeatClicked = 'hd21-cvo-heating-seat-',
  TouringHeatingSeatClicked = 'hd21-touring-color-',
  EngineButtonClicked = 'hd21-street-bob-motor-sound',
  LoginSuccess = 'hd21-login : success',
  SendMessage = 'hd21-chat-sendMessage',
  FilterClicked = 'hd21-gallery-header-',
  GalleryProductView = 'hd21-gallery-productView-',
  GalleryProductClick = 'hd21-gallery-productClick-',
  GalleryProductStarred = 'hd21-gallery-productFav-',
  VodCtaClick = 'hd21-vod-',
  VodVideoPlayed = 'hd21-vod-',
  OnlyFunctionalCookiesClicked = 'hd21-only-functional-cookies',
  // end of legacy events from the first HD site
  CountDownCtaClicked = 'hd21-C&T-register',
  MenuItemClicked = 'hd21-',
  HomeRegisterButtonClicked = 'hd21-home-register',
  HomeRegisterNoThanksClicked = 'hd21-home-register-no',
  DownloadClicked = 'hd21-home-download',
  FindADealerClicked = 'hd21-',
  StartVideoClicked = 'hd21-trh-video',
  ExploreButtonClicked = 'hd21-',
  OverViewColorChanged = 'hd21-overview-',
  OverViewBikeChanged = 'hd21-overview-',
  PostCardExploreButtonClicked = 'hd21-cya-',
  AdventureRideChanged = 'hd21-cya-change-',
  AdventureAccesoriesChanged = 'hd21-cya-',
  RidingGearChanged = 'hd21-rg-',
}

let onlyFunctionalCookiesClicked = false;

export const triggerEvent = (key: AnalyticsEvents, sufix = '', disableTracking = false) => {
  if (onlyFunctionalCookiesClicked) return;
  let event = key as string;
  if (sufix && typeof sufix === 'string') {
    event += sufix.replaceAll(' ', '');
  }
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(`analytics event: ${event}`);
  }
  document.body.dispatchEvent(new Event(event));

  if (disableTracking) onlyFunctionalCookiesClicked = true;
};
