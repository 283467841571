import type { Timestamp } from '../types/opaque';

export const getServerTime = (): Promise<Timestamp> => {
  return fetch('https://reveal.harley-davidson.com/api/server/time')
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      return response.json() as Promise<{ timestamp: Timestamp }>;
    })
    .then((response) => response.timestamp);
};
