import { makeAutoObservable } from 'mobx';
import { Styles } from './data/enum/styles';
import createStoreContext from './utils/createStoreContext';
import type { SidebarModalProps } from './types/sidebarModal';
import { getCurrentPhase } from './utils/getCurrentPhase';
import { riderStoriesData } from './data/riderStories';
import type { Phases } from './data/enum/phases';
import { Phases as PhasesEnum } from './data/enum/phases';

export const [GlobalStoreProvider, useGlobalStore] = createStoreContext(
  class GlobalStore {
    public globalStyle: Styles = Styles.DARK;
    public riderStoriesRiderId: number = 0;
    public cvoBikeId: number = 0;
    public touringBikeId: number = 0;
    public modalData: SidebarModalProps | null = null;
    public isLoading: boolean = true;
    public audioIsPlaying: boolean = false;
    public sessionToken: string = '';
    public isUserLogged: boolean = process.env.NODE_ENV === 'development';
    public currentPhase: Phases = PhasesEnum.LAUNCH;
    public currentSection: number = 0;
    public isFullscreenVideoPlaying: boolean = false;
    public showMenu: boolean = true;

    public constructor() {
      // NOTE: don't add anything else here  unless you know what you are doing
      // as the constructor will be executed twice due to `React.StrictMode`
      makeAutoObservable(this);

      getCurrentPhase()
        .then((currentPhase) => {
          this.setCurrentPhase(currentPhase);
        })
        .catch((error) => {
          // if server time call fails we show day 1 as fallback
          this.setCurrentPhase(PhasesEnum.LAUNCH);
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }

    public setCurrentPhase = (phase: Phases) => {
      this.currentPhase = phase;
    };

    public setGlobalStyle = (style: Styles) => {
      this.globalStyle = style;
    };

    public setModalData = (data: SidebarModalProps | null) => {
      this.modalData = data;
    };

    public setRiderStoriesRiderId = (index: number) => {
      this.setModalData(null);
      this.riderStoriesRiderId = index;
    };

    public setCVOBikeId = (index: number) => {
      this.setModalData(null);
      this.cvoBikeId = index;
    };

    public setTouringBikeId = (index: number) => {
      this.setModalData(null);
      this.touringBikeId = index;
    };

    public getCurrentRiderStoriesRider = () => {
      const { riders } = riderStoriesData;
      return riders[this.riderStoriesRiderId];
    };

    public setLoading = (loading: boolean) => {
      this.isLoading = loading;
    };

    public setAudioIsPlaying = (isPlaying: boolean) => {
      this.audioIsPlaying = isPlaying;
    };

    public setSessionToken = (token: string) => {
      this.sessionToken = token;
      this.isUserLogged = this.sessionToken.length > 0;
    };

    public setCurrentSection = (sectionIndex: number) => {
      this.currentSection = sectionIndex;
    };

    public setIsFullscreenVideoPlaying = (value: boolean) => {
      this.isFullscreenVideoPlaying = value;
    };

    public setShowMenu = (value: boolean) => {
      this.showMenu = value;
    };
  },
);

export type GlobalStore = ReturnType<typeof useGlobalStore>;
